import './bootstrap';

import Form from './core/Form';
import Modal from './plugins/modal/ModalPlugin';
import filters from "./filters";

import ConfirmButton from './components/ConfirmButton.vue';
import ConfirmDialog from './components/ConfirmDialog.vue';

window.Form = Form;

import { createApp } from 'vue';
import { defineAsyncComponent } from 'vue';

import Trans from './core/Trans';

const app = createApp(
    {
        components: {
            ConfirmButton,
            ConfirmDialog,
        },
    }
)

// Use plugins
app.use(Modal);
app.use(filters);

app.mixin(Trans);

app.component(
    "register-multiple-entities",
    defineAsyncComponent(() => import("./components/register/MultipleEntities.vue"))
);
app.component(
    "customer-form",
    defineAsyncComponent(() => import("./components/customers/CustomerForm.vue"))
);
app.component(
    "user-form",
    defineAsyncComponent(() => import("./components/users/UserForm.vue"))
);
app.component(
    "file-search",
    defineAsyncComponent(() => import("./components/files/FileSearch.vue"))
);
app.component(
    "file-parties",
    defineAsyncComponent(() => import("./components/files/Parties.vue"))
);
app.component(
    "file-fin-detail",
    defineAsyncComponent(() => import("./components/files/FinDetail.vue"))
);
app.component(
    "file-evolution",
    defineAsyncComponent(() => import("./components/files/Evolution.vue"))
);
app.component(
    "file-settlements",
    defineAsyncComponent(() => import("./components/files/Settlements.vue"))
);
app.component(
    "file-payment-plan",
    defineAsyncComponent(() => import("./components/files/PaymentPlan.vue"))
);
app.component(
    "file-agenda",
    defineAsyncComponent(() => import("./components/files/Agenda.vue"))
);
app.component(
    "file-documents",
    defineAsyncComponent(() => import("./components/files/Documents.vue"))
);
app.component(
    "file-messages",
    defineAsyncComponent(() => import("./components/files/Messages.vue"))
);
app.component(
    "file-info",
    defineAsyncComponent(() => import("./components/files/Info.vue"))
);
app.component(
    "file-detail",
    defineAsyncComponent(() => import("./components/files/Detail.vue"))
);
app.component(
    "tabs",
    defineAsyncComponent(() => import("./components/tabs/tabs.vue"))
);
app.component(
    "tab",
    defineAsyncComponent(() => import("./components/tabs/tab.vue"))
);
app.component(
    "messages",
    defineAsyncComponent(() => import("./components/messages/Messages.vue"))
);
app.component(
    "messages-form",
    defineAsyncComponent(() => import("./components/messages/Form.vue"))
);
app.component(
    "new-file-form",
    defineAsyncComponent(() => import("./components/new-files/NewFileForm.vue"))
);
app.component(
    "payment-plan-form",
    defineAsyncComponent(() => import("./components/payment-plan/PaymentPlanForm.vue"))
);
app.component(
    "report-generator",
    defineAsyncComponent(() => import("./components/report-generator/ReportGenerator.vue"))
);
app.component(
    "dashboard-kpis",
    defineAsyncComponent(() => import("./components/dashboards/DashboardKpis.vue"))
);
app.component(
    "dashboard-activity",
    defineAsyncComponent(() => import("./components/dashboards/Activity.vue"))
);
app.component(
    "evi-index",
    defineAsyncComponent(() => import("./components/eviindex/EviIndex.vue"))
);
app.component(
    "gauge",
    defineAsyncComponent(() => import("./components/dashboards/Gauge.vue"))
);
app.component(
    "stack-chart",
    defineAsyncComponent(() => import("./components/eviindex/StackChart.vue"))
);
app.component(
    "feedback-kpis",
    defineAsyncComponent(() => import("./components/feedback/FeedbackKpis.vue"))
);
app.mount('#app');

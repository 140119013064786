<template>
    <ntriga-modal name="dialog">
        {{ params.message }}

        <template v-slot:footer>
            <button
                class="btn btn-secondary"
                @click.prevent="handleClick(false)"
                v-if="params.cancelButton"
                v-text="params.cancelButton"
            >
            </button>

            <button
                class="btn btn-primary"
                @click.prevent="handleClick(true)"
                v-if="params.confirmButton"
                v-text="params.confirmButton"
            >
            </button>
        </template>
    </ntriga-modal>
</template>

<script>
    import Modal from '../plugins/modal/ModalPlugin';

    export default {
        data() {
            return {
                params: {
                    message: this.__('Bent u zeker?'),
                    confirmButton: this.__('Verder'),
                    cancelButton: this.__('Annuleren')
                }
            };
        },

        beforeMount() {
            Modal.events.$on('show', params => {
                Object.assign(this.params, params);
            });
        },

        methods: {
            handleClick(confirmed) {
                Modal.events.$emit('clicked', confirmed);

                this.$ntrigaModal.hide();
            }
        }
    }
</script>

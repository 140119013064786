<template>
    <button @click="confirm">
        <slot></slot>
    </button>
</template>

<script>
    const stringContinue = window._translations.json['Verder'];
    const stringCancel = window._translations.json['Annuleren'];

    export default {
        props: {
            message: {},
            confirmButton: { default: stringContinue },
            cancelButton: { default: stringCancel }
        },

        data() {
            return { confirmed: false };
        },

        methods: {
            confirm(e) {
                if (this.confirmed) {
                    return;
                }

                e.preventDefault();

                this.$ntrigaModal.dialog(this._props)
                    .then(confirmed => {
                        this.confirmed = confirmed;

                        if (confirmed) {
                            this.$el.click();
                        }
                    });
            }
        }
    }
</script>